import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {FormControl, FormLabel, FormErrorMessage} from '@chakra-ui/react';

export const SearchableSelect = ({
  label,
  placeholder,
  options = [],
  isDisabled,
  error,
  touched,
  onChange,
  value,
  color = 'gray.400',
  fontWeight = 'normal',
  formatOptionLabel,
  async = false,
  defaultOptions = [],
  loadOptions,
  isRequired = true,
  maxHeight = '300px'
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderBottom: state.isFocused ? '2px solid #E2E8F0' : '1px solid #E2E8F0',
      borderRadius: '0',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#3182CE',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#A0AEC0',
      '&:hover': {
        color: '#3182CE',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#2D3748',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight,
      overflowY: 'auto',
    }),
  }

  const SelectComponent = async ? AsyncSelect : Select

  return (
    <FormControl isInvalid={error && touched} my="2" isRequired={isRequired}>
      <FormLabel color={color} mb="0" fontWeight={fontWeight}>
        {label}
      </FormLabel>
      <SelectComponent
        cacheOptions={async}
        defaultOptions={async ? defaultOptions : undefined}
        loadOptions={async ? loadOptions : undefined}
        options={!async ? options : undefined}
        onChange={onChange}
        isDisabled={isDisabled}
        placeholder={placeholder}
        styles={customStyles}
        value={
          async
            ? value
              ? defaultOptions.find((option) => option.phid === value)
              : null
            : options.find((option) => option.value === value)
        }
        formatOptionLabel={formatOptionLabel}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
