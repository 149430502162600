import {IoIosMale} from "react-icons/io"
import {IoFemaleOutline} from "react-icons/io5";
import verifyAccess from "../../../utils/verifyAccess"
import renderBlurredText from "../../../utils/renderBlurredText";
import {getAge} from "./getAge"
import {Text} from "@chakra-ui/react";

const getIcon = (gender, isBlurred) => {
  if (isBlurred) {
    return <IoIosMale style={{ filter: 'blur(4px)' }} />
  }
  
  if (gender === "Male" || gender === "Trans Man") return <IoIosMale />
  if (gender === "Female" || gender === "Trans Women") return <IoFemaleOutline/>
  return null
}

export const getName = (dashState, patient, groupsMap) => {
  const hasGenderAccess = verifyAccess(dashState, "gender");
  const hasDobAccess = verifyAccess(dashState, "dob");

  const dob = hasDobAccess ?
    !patient.dob 
      ? "unknown "
      : getAge(patient.dob) + " y/o "
    : renderBlurredText("00 y/o ", true)

  const group = patient.groupId ? groupsMap[patient.groupId]?.name : ""

  return (
    <Text fontSize={"11px"} display={"flex"} alignItems={"center"} gap={1}>
      {dob}
      {getIcon(patient.gender, !hasGenderAccess)}
      {group}
    </Text>
  )
}
