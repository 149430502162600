import {downloadFile} from "./helpers/downloadFile";
import {deleteItem} from "./helpers/deleteItem";
import {FiDownload} from "react-icons/fi";
import {AiOutlineDelete, AiFillEye} from "react-icons/ai";
import {BsFillSendFill} from "react-icons/bs";
import {
  Box,
  Icon,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  Textarea,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {FiMail} from "react-icons/fi";
import {
  sendPatientFileEmail,
  supplementaryCrud,
  apiAction,
  crud,
} from "../../../crudRequests";

import {useContext, useState, useReducer, useEffect} from "react";
import {faxFile} from "./helpers/faxFile";
import PreviewFile from "./PreviewFile";
import {previewFile} from "./helpers/previewFile";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import ProvidersModal from "./ProvidersModal";
import {FileLogsModal} from "./FileLogsModal";
import {fileProxy} from "./helpers/fileProxy";

function SendFileEmailDialog(props) {
  const toast = useToast()
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.type === "SET-LOADING") {
        return {...state, loading: action.payload};
      }
      if (action.type === "SET-EMAIL") {
        return {...state, email: action.payload};
      }
      if (action.type === "SET-MESSAGE") {
        return {...state, message: action.payload, loading: false};
      }
      if (action.type === "REMOVE-EMAIL") {
        return {
          ...state,
          emails:
            state.emails.filter((email) => {
              return email !== action.payload;
            }) || [],
        };
      }
      if (action.type === "ADD-EMAIL") {
        if (
          state.emails.find(
            (currentEmail) => currentEmail === action.payload
          ) ||
          !action.payload.includes("@")
        ) {
          return state;
        }
        return {
          ...state,
          emails: [...state.emails, action.payload],
          email: "",
        };
      }
    },
    {
      message: "",
      email: "",
      loading: true,
      emails: [props.email],
    }
  );

  const org = props.dashState.organization;
  let smap = {
    "{{patientName}}": `${props.patient?.fName || ""} ${
      props.patient?.lName || ""
    }`,
    "{{sender}}": `${org.name || ""}`,
  };
  useEffect(() => {
    async function getTemplateData() {
      try {
        let req = [
          {
            db: "metadata",
            collection: "notifications",
            parameters: [
              {
                type: "patient_file",
              },
            ],
            method: "findOne",
          },
        ];
        const res = await supplementaryCrud(props.dashState, req);
        dispatch({
          type: "SET-MESSAGE",
          payload: res.data[0]?.emailContent
            .replace(/{{\w+}}/g, (s) => smap[s])
            .replace(/<br\s*\/?>/gi, "\n")
            .replace(/<[^>]*>/g, ""),
        });
      } catch (error) {
        console.error(error);
      }
    }
    getTemplateData();
  }, []);

  const handleSendClick = async () => {
    try {
      setIsLoading(true);
      const file = await fileProxy(
        props.dashState,
        "brightlighthealth",
        props.path,
        props.selectedFile,
        true
      );

      await sendPatientFileEmail(props.dashState, {
        templateType: props.path.includes("Requisition")
          ? "patient_requisition"
          : "patient_file",
        text: state.message,
        email: state.emails,
        sender: org.name,
        attachments: [
          {
            filename: props.selectedFile,
            path: `data:application/pdf;base64,${file.data.data}`,
          },
        ],
      });

      await crud(props.dashState, [
        {
          db: props.dashState.db || "blh",
          collection: "auditLogs",
          parameters: [
            {
              collection: "files",
              action: "EMAIL-FILE",
              triggeredBy: props.dashState.admin.id,
              patientId: props.patient.pid,
              payload: {
                fileName: props.selectedFile,
                path: props.path,
                emails: state.emails,
                timestamp: new Date().getTime(),
              },
            },
          ],
          method: "insertOne",
        },
      ]);

      if (props.path.includes("Requisition")) {
        await apiAction(props.dashState, {
          action: "EMAIL-REQUISITION",
          payload: {
            fileName: props.selectedFile,
            path: props.path,
            emails: state.emails,
          },
        });
      }
      toast({
        title: "Email sent successfully",
        description: "The file has been emailed to the specified recipients.",
        status: "success",
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
      toast({
        title: "Failed to send email",
        description: error?.message || "An error occurred while sending email.",
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Badge
        onClick={onOpen}
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        ml="2"
        colorScheme="purple"
        cursor={"pointer"}
      >
        <Icon as={FiMail} fontSize="15px" mt="-0.5" /> Email
      </Badge>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share file via email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Email recipient(s)</Text>
            {state.emails.map((thisMail) => {
              return (
                <Tag
                  borderRadius="full"
                  variant="solid"
                  colorScheme="blue"
                  key={thisMail}
                  my="0.5rem"
                >
                  <TagLabel>{thisMail}</TagLabel>
                  <TagCloseButton
                    onClick={(evt) => {
                      dispatch({type: "REMOVE-EMAIL", payload: thisMail});
                    }}
                  />
                </Tag>
              );
            })}
            <Box flexDirection="row">
              <Input
                w="80%"
                mr="1rem"
                placeholder="Add email..."
                value={state.email}
                onChange={(e) =>
                  dispatch({type: "SET-EMAIL", payload: e.target.value})
                }
              />
              <Button
                isDisabled={!state.email.length}
                onClick={() => {
                  dispatch({type: "ADD-EMAIL", payload: state.email});
                }}
              >
                Add
              </Button>
            </Box>
            <Text pt="1rem">Email body</Text>
            <Textarea
              minHeight={"150px"}
              placeholder="Write the message..."
              value={state.message}
              onChange={(e) =>
                dispatch({type: "SET-MESSAGE", payload: e.target.value})
              }
              disabled={state.loading}
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={!state.emails.length}
              isLoading={isLoading}
              onClick={handleSendClick}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function SelectedControls({
  selectedFile,
  path,
  setFiles,
  setSelectedFile,
  preview,
  setPreview,
  toast,
}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box mr="1">
      <PreviewFile
        preview={preview}
        setPreview={setPreview}
        state={dashState}
        path={path}
      />
      {selectedFile && (
        <Flex gap={1} alignContent={"space-around"} align={"flex-start"}>
          {selectedFile?.slice(-1) != "/" && (
            <>
              <ProvidersModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                state={dashState}
                faxFn={(number) =>
                  faxFile(
                    dashState,
                    number,
                    path,
                    selectedFile,
                    toast,
                    patient.pid
                  )
                }
              />

              {selectedFile.endsWith(".pdf") && (
                <SendFileEmailDialog
                  email={patient.email}
                  dashState={dashState}
                  path={path}
                  selectedFile={selectedFile}
                  patient={patient}
                />
              )}
              <Badge
                onClick={() =>
                  previewFile(dashState, path, selectedFile, setPreview, toast)
                }
                fontSize="12px"
                rounded="md"
                p="1"
                px="2"
                ml="2"
                colorScheme="blue"
                cursor={"pointer"}
              >
                <Icon as={AiFillEye} fontSize="15px" mt="-0.5" /> Preview
              </Badge>
              <Badge
                onClick={() =>
                  downloadFile(dashState, path, selectedFile, toast)
                }
                fontSize="12px"
                rounded="md"
                p="1"
                px="2"
                ml="2"
                colorScheme="orange"
                cursor={"pointer"}
              >
                <Icon as={FiDownload} fontSize="15px" mt="-0.5" /> Download
              </Badge>
              <Badge
                onClick={() => {
                  setIsOpen(true);
                }}
                fontSize="12px"
                rounded="md"
                p="1"
                px="2"
                ml="2"
                colorScheme="green"
                cursor={"pointer"}
              >
                <Icon as={BsFillSendFill} fontSize="12px" mt="-0.5" /> Fax
              </Badge>
              <FileLogsModal
                dashState={dashState}
                fileName={selectedFile}
                filePath={path}
              />
            </>
          )}
          <Badge
            onClick={() =>
              deleteItem(
                dashState,
                path,
                selectedFile,
                setFiles,
                setSelectedFile,
                toast
              )
            }
            fontSize="12px"
            rounded="md"
            p="1"
            px="2"
            ml="2"
            colorScheme="red"
            cursor={"pointer"}
          >
            <Icon as={AiOutlineDelete} fontSize="15px" mt="-0.5" /> Delete
          </Badge>
        </Flex>
      )}
    </Box>
  );
}
