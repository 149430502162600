import {
  Box,
  Text,
  useToast,
  Center,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  StatGroup,
  Stack,
} from "@chakra-ui/react";
import {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {HiOutlinePlus} from "react-icons/hi";
import {IoSearch} from "react-icons/io5";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../../pages/Patients";
import fetchProducts from "../../../Products/helpers/fetchProducts";
import fetchCategories from "../../../Products/helpers/fetchCategories";
import ProductsTable from "../../../Products/ProductsTable";
import EditOrCreate from "../../../Products/EditOrCreate";
import {GiReceiveMoney} from "react-icons/gi";
import {MdClose} from "react-icons/md";
import SalesModal from "../../../Products/SalesModal";
export default function Stock() {
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const patient = useContext(PatientContext);
  const toast = useToast();
  const [showEditOrCreateModal, setShowEditOrCreateModal] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [prevLocation, setPrevLocation] = useState(dashState.selectedLocation);
  const [waiting, setWaiting] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allowSelection, setAllowSelection] = useState(false);

  const [selections, setSelections] = useState(new Set());
  useEffect(() => {
    fetchProducts(dashState, setIsLoading, setProducts, toast);
    fetchCategories(dashState, setIsLoading, setCategories, toast);
  }, []);

  const {categoriesMap, fields} = useMemo(() => {
    let categoriesMap = categories.reduce((acc, c) => {
      acc[c.id] = c;
      return acc;
    }, {});

    let fields = {
      Id: (p) => p.id,
      "Product Name": (p) => p.name,
      Category: (p) => categoriesMap[p.categoryId]?.name || "N/A",
      Description: (p) => p.description,
      Quantity: (p) => p.stock,
      "Min Inventory": (p) => p.minInventory ?? "",
      "Max Inventory": (p) => p.maxInventory ?? "",
      Price: (p) => p.price,
    };
    return {categoriesMap, fields};
  }, [categories]);

  if (prevLocation !== dashState.selectedLocation) {
    setPrevLocation(dashState.selectedLocation);
    fetchProducts(dashState, setIsLoading, setProducts, toast);
  }

  let filteredList = useMemo(() => {
    function filterText(product, text) {
      let regex = new RegExp(text, "i");
      if (text.length === 0) {
        return product;
      }
      return (
        product["name"]?.match(regex) ||
        product["description"]?.match(regex) ||
        product["category"]?.match(regex) ||
        String(product["price"]).match(regex) ||
        String(product["stock"]).match(regex)
      );
    }

    return products.filter((p) => filterText(p, searchText));
  }, [searchText, products]);

  return (
    <>
      {dashState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      {showSalesModal && (
        <SalesModal
          {...{
            selections,
            setSelections,
            action: () => setShowSalesModal(false),
            state: dashState,
            dispatch,
            setProducts,
            patient,
          }}
        />
      )}
      {showEditOrCreateModal && (
        <EditOrCreate
          {...{
            showEditOrCreateModal,
            setShowEditOrCreateModal,
            selectedItem,
            setSelectedItem,
            dashState,
            dispatch,
            setProducts,
            categories,
            categoriesMap,
            toast,
            waiting,
            setWaiting,
          }}
        />
      )}

      <Box
        w="full"
        h="full"
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
      >
        <Flex
          justify={"space-between"}
          w="full"
          align={"center"}
          mb="1"
          pl="0"
          pr="2"
        >
          <Flex align={"center"} gap={4}>
            <InputGroup p="1" w="60">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" mt="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value.toLowerCase());
                }}
              />
            </InputGroup>
            <Flex align={"center"} gap={1}>
              <Button
                w="180px"
                leftIcon={<GiReceiveMoney fontSize={"1.5rem"} />}
                colorScheme={
                  allowSelection && selections.size > 0 ? "green" : "teal"
                }
                cursor={!waiting ? "pointer" : "not-allowed"}
                onClick={() => {
                  if (allowSelection && selections.size > 0)
                    setShowSalesModal(true);
                  else setAllowSelection(true);
                }}
              >
                {allowSelection && selections.size > 0
                  ? "Proceed to Sale"
                  : allowSelection
                  ? "Select Products"
                  : "Sell Products"}
              </Button>
              {allowSelection && (
                <Icon
                  as={MdClose}
                  color={"gray.500"}
                  _hover={{color: "gray.700"}}
                  w={6}
                  h={6}
                  cursor={"pointer"}
                  onClick={() => {
                    setAllowSelection("");
                    setSelections(new Set());
                  }}
                />
              )}
            </Flex>
          </Flex>

          <Button
            leftIcon={<HiOutlinePlus />}
            colorScheme="blue"
            pl={2}
            onClick={() => setShowEditOrCreateModal(true)}
          >
            Create
          </Button>
        </Flex>
        <ProductsTable
          {...{
            dashState,
            products: filteredList,
            setProducts,
            fields,
            searchText,
            selectedItem,
            setSelectedItem,
            setShowEditOrCreateModal,
            toast,
            allowSelection,
            selections,
            setSelections,
          }}
        />
      </Box>
    </>
  );
}
