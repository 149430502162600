// we map the data or feature we want to acces with the key of the access object
const accessMap = {
  // data
  fName: "patientName",
  lName: "patientName",
  gender: "patientGender",
  email: "patientEmail",
  age: "patientAge",
  phone: "patientPhone",
  address: "patientAddress",
  dob: "patientAge",
  city: "patientAddress",
  state: "patientAddress",
  intake: "intake",
  summary: "summary",
  height: "height",
  weight: "weight",
  // features
  // pending
  instantMessaging: "instantMessaging",
  transferPatient: "transferPatient",
  bookAppointment: "bookAppointment",
  documents: "documents",
  notes: "notes",
  setBy: "setBy",
  reports: "reports",
  createDraftRx: "createDraftRx",
};

export default function verifyAccess(state, field) {
  if (state.userType === "admin" && state.admin.super) return true;

  // these are never forbidden, so we return early to avoid unnecessary computation
  if (field === ("intake" || "summary" || "height" || "weight")) return true;

  const accessKey = accessMap[field];
  let accessList;

  if (state.userType === "doctor") {
    accessList = state.doctor?.access;
  } else if (state.userType === "admin") {
    accessList = state.admin?.access;
    if (field === "reports") {
      accessList = accessList?.admAccessSections;
    }
  }

  if (accessList?.[accessKey]) {
    return true;
  } else {
    return false;
  }
}
