import {useEffect, useState} from "react";
import PrescriptionModal from "./PrescriptionModal";

import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";

import {fetchAndExpire} from "./helpers/fetchAndExpire";
import expirePrescription from "./helpers/expirePrescription";
import {datediff} from "./PrescribeDrug";
import renewPrescription from "./helpers/renewPrescription";
import {crud} from "../../../crudRequests";

const PrescriptionReport = ({state, pid, patient}) => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [currPrescription, setCurrPrescription] = useState(null);
  const [open, setOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchAndExpire(setPrescriptions, state, {pid}, toast);
  }, [patient]);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedPrescription, setSelectedPrescription] = useState(null);

  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");
  const [length_days, setLengthDays] = useState(0);

  const [loading, setLoading] = useState(false);

  const [toDisplay, setToDisplay] = useState([]);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (prescriptions && filter) {
      setToDisplay(
        prescriptions.filter((prescription) => !prescription.expired)
      );
    } else {
      setToDisplay(prescriptions);
    }
  }, [prescriptions, filter]);

  const handleReviewComplete = async (prescriptionId) => {
    try {
      await crud(state, [
        {
          db: state.db || "blh",
          collection: "prescriptions",
          method: "updateOne",
          parameters: [{presId: prescriptionId}, {$set: {reviewRequired: false}}],
        }
      ]);

      // Update local state
      setPrescriptions(prevPrescriptions => 
        prevPrescriptions.map(p => 
          p.presId === prescriptionId 
            ? {...p, reviewRequired: false}
            : p
        )
      );

      toast({
        title: "Review Complete",
        description: "Prescription has been marked as reviewed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update review status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {!prescriptions ||
        (prescriptions.length === 0 && (
          <Text textAlign="center" fontWeight="medium" color="gray.600" mt="4">
            No prescriptions found for this patient
          </Text>
        ))}
      {toDisplay?.length > 0 && (
        <Flex gap={4}>
          <Button
            bg={!filter ? "blue.500" : "grey"}
            color="white"
            onClick={() => setFilter(false)}
          >
            All
          </Button>
          <Button
            bg={filter ? "blue.500" : "grey"}
            color="white"
            onClick={() => setFilter(true)}
          >
            Active
          </Button>
        </Flex>
      )}
      <Grid templateColumns="auto 1fr 1fr" p={4} rounded="lg" mt={2}>
        <GridItem colSpan={3}>
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            {toDisplay?.map((prescription, index) => (
              <Card key={index}>
                {!prescription?.expired && (
                  <Box
                    w={2}
                    h={2}
                    m="10px"
                    position="absolute"
                    right="0"
                    bg="green.500"
                    rounded="full"
                  />
                )}
                <CardBody>
                  {prescription.prescriptionType === "therapy" ? (
                    <>
                      <p>{prescription?.therapyName}</p>
                      <p>{prescription?.length_days} days</p>
                    </>
                  ) : (
                    <>
                      <p>
                        Rx: <b>{prescription.drugName}</b>
                      </p>
                      <p>
                        Sig: <b>{prescription.signetur}</b>
                      </p>
                      <p>
                        Disp/Qty: <b>{prescription.qty}</b>
                      </p>
                      <p>
                        Rf: <b>{prescription.refills}</b>
                      </p>
                    </>
                  )}
                  {prescription.reviewRequired && (
                    <Badge colorScheme="red" mt={2}>
                      Review Required
                    </Badge>
                  )}
                </CardBody>
                <CardFooter className="flex gap-2 mt-2">
                  <Button
                    rounded="full"
                    textColor="white"
                    onClick={() => {
                      setCurrPrescription(prescription);
                      setOpen(true);
                      if (state.selectedDoctor && prescription.reviewRequired) {
                        handleReviewComplete(prescription.presId);
                      }
                    }}
                    size="sm"
                    bg="black"
                  >
                    preview
                  </Button>
                  {state.userType === "doctor" && (
                    <Button
                      textColor="white"
                      bg="blue.500"
                      rounded="full"
                      size="sm"
                      onClick={() => {
                        setSelectedPrescription(prescription);
                        setStartDate(prescription?.start_date);
                        setEndDate(prescription?.end_date);
                        setLengthDays(prescription?.length_days);
                        onOpen();
                      }}
                    >
                      renew
                    </Button>
                  )}
                  {!prescription?.expired && (
                    <Button
                      textColor="white"
                      bg="red.500"
                      rounded="full"
                      size="sm"
                      onClick={() => {
                        if (state.selectedDoctor && prescription.reviewRequired) {
                          handleReviewComplete(prescription.presId);
                        }
                        expirePrescription(
                          prescription.presId,
                          setPrescriptions,
                          state,
                          {pid},
                          toast
                        )
                      }}
                    >
                      expire
                    </Button>
                  )}
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </GridItem>
        <Modal isOpen={isOpen && selectedPrescription} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedPrescription?.drugName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb={4}>
                <Text>start date</Text>
                <Input
                  type="date"
                  value={start_date}
                  onChange={(e) => {
                    if (end_date && datediff(e.target.value, end_date) < 0) {
                      toast({
                        title: "Invalid Date",
                        description: "Start date cannot be after the end date",
                        status: "error",
                        isClosable: true,
                      });
                      return;
                    }
                    setStartDate(e.target.value);
                    // calculate length in days based off difference in start and end dates
                    if (end_date) {
                      setLengthDays(datediff(e.target.value, end_date));
                    }
                  }}
                />
              </Box>
              <Box mb={4}>
                <Text>end date</Text>
                <Input
                  type="date"
                  value={end_date}
                  onChange={(e) => {
                    if (
                      start_date &&
                      datediff(start_date, e.target.value) < 0
                    ) {
                      toast({
                        title: "Invalid Date",
                        description: "Start date cannot be after the end date",
                        status: "error",
                        isClosable: true,
                      });
                      return;
                    }
                    setEndDate(e.target.value);
                    // calculate length in days based off difference in start and end dates
                    if (start_date) {
                      setLengthDays(datediff(start_date, e.target.value));
                    }
                  }}
                />
              </Box>
              <Box mb={4}>
                <Text>length (days)</Text>
                <Input
                  // className="w-14 px-4 h-12 border-none bg-[#e9e9e9] rounded-full outline-none text-[#7E7E7E]"
                  value={length_days}
                  onChange={(e) => {
                    setLengthDays(e.target.value);
                    if (end_date) {
                      try {
                        setEndDate(
                          moment(start_date)
                            .add(e.target.value, "days")
                            .format("YYYY-MM-DD")
                        );
                      } catch (e) {
                        console.log({e});
                      }
                    }
                  }}
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                disabled={loading || !end_date || !start_date || !length_days}
                onClick={() => {
                  setLoading(true);
                  toast.promise(
                    renewPrescription(
                      selectedPrescription?.presId,
                      setPrescriptions,
                      state,
                      {pid},
                      toast,
                      start_date,
                      end_date,
                      length_days
                    ).then(setLoading(false)),
                    {
                      success: {
                        title: "Success!",
                        description: "Prescription renewed successfully",
                      },
                      error: {
                        title: "Error",
                        description: "Something wrong",
                      },
                      loading: {
                        title: "Loading...",
                        description: "Please wait",
                      },
                    }
                  );
                }}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {currPrescription && (
          <PrescriptionModal
            {...currPrescription}
            state={state}
            pid={pid}
            medications={[currPrescription]}
            therapies={[currPrescription]}
            modal={open}
            setModal={setOpen}
            disableSave={true}
          />
        )}
      </Grid>
    </>
  );
};

export default PrescriptionReport;
