import {
  Button,
  ButtonGroup,
  FormControl,
  Input,
  Stack,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  Portal,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import {useState, useRef, useEffect} from "react";
import {SearchableSelect} from "../../SearchableSelect";

export const DynamicSelect = ({
  values,
  saveValues,
  selectedOption,
  setSelectedOption,
  placeholder
}) => {
  const firstFieldRef = useRef(null);
  const [value, setValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {onOpen, onClose, isOpen} = useDisclosure();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await saveValues(value, selectedOption)
    setValue("");
    setIsSubmitting(false);
    onClose();
  };

  const options = values ? [
    {value: "", label: "-"},
    ...values.map((value) => ({
      value: value.groupId,
        label: value.name,
      })),
    ]
    : [];

  useEffect(()=>{
    const selectedGroup = values?.find(v => v.groupId === selectedOption);
    setValue(selectedGroup?.name || "")
  }, [values])

  return (
    <Stack direction="row" width="100%" spacing={4}>
      <Box width="30%" mt={2}>
        <Popover
          placement="right-start"
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          initialFocusRef={firstFieldRef}
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button
              isDisabled={false}
              colorScheme="blue"
              width="100%"
            >
              {selectedOption ? "Edit" : "Add"}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
                <PopoverBody>
                  <FormControl my="2">
                    <Input
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      variant="flushed"
                      placeholder={placeholder}
                    />
                  </FormControl>
                </PopoverBody>
                <PopoverFooter border="0">
                  <Flex justifyContent="end">
                    <ButtonGroup size="sm">
                      <Button
                        colorScheme="blue"
                        variant="ghost"
                        onClick={onClose}
                      >
                        Go Back
                      </Button>
                      <Button
                        isLoading={isSubmitting}
                        colorScheme="blue"
                        disabled={isSubmitting}
                        loadingText="Saving..."
                        onClick={handleSubmit}
                      >
                        {selectedOption ? "Edit" : "Add"}
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
      <Box width="70%">
        <SearchableSelect
          label=""
          placeholder="-"
          options={options}
          onChange={(option) => {
            setSelectedOption(option.value);
            setValue(option.value.length > 0 ? option.label : "");
          }}
          value={selectedOption}
          isDisabled={false}
          isRequired={false}
          maxHeight="150px"
        />
      </Box>
    </Stack>
  );
}
