import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Input,
  Text,
  useToast,
  Badge,
} from "@chakra-ui/react";
import React, {useState, useMemo} from "react";
import SelectionsTable from "./SelectionsTable";
import BuyerInformation from "./BuyerInformation.js";

import SelectPatient from "./SelectPatient.js";
import {ProductPaymentModal} from "../../appointments/payment/ProductPaymentModal.jsx";
export default function SalesModal({
  selections,
  setSelections,
  action,
  state,
  setProducts,
  patient,
}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [patientId, setPatientId] = useState(patient?.[0]?.pid || null);
  const [selectedProducts, setSelectedProducts] = useState(
    [...selections.values()].map((e) => ({...e, quantity: 1}))
  );
  const [fields, setFields] = useState(() => {
    let location = state.locations?.find(
      (l) => l.lid === state.selectedLocation
    );

    return {
      pid: patientId,
      firstName: patient?.[0]?.fName || "",
      lastName: patient?.[0]?.lName || "",
      pEmail: patient?.[0]?.email || "",
      phone: patient?.[0]?.phone || "",
      tax: location.productsTaxRate || 0,
      note: "",
    };
  });
  const [loading, setLoading] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const patientsMap = useMemo(() => {
    return state.patients.reduce((acc, c) => {
      acc[c.pid] = c;
      return acc;
    }, {});
  }, []);

  let amount = selectedProducts.reduce((acc, el) => {
    return acc + parseFloat(el.price) * parseFloat(el.quantity);
  }, 0);

  amount = (amount * (1 + fields.tax / 100)).toFixed(2);

  return (
    <Modal
      isCentered
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        action();
      }}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent p={1} overflow={"hidden"}>
        <ModalHeader borderBottom={"1px"} borderColor={"gray.400"}>
          Sale Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="10">
          <form
            id="buyerInfo"
            onSubmit={async (e) => {
              e.preventDefault();
              setPaymentModalOpen(true);
              // await onSell();
            }}
            m="2"
          >
            <SelectPatient
              {...{patientId, setPatientId, setFields, state, patientsMap}}
            />

            <Flex w="full" direction={"column"} gap={6}>
              <BuyerInformation {...{fields, setFields, state}} />

              <SelectionsTable
                {...{
                  state,
                  selectedProducts,
                  setSelectedProducts,
                  loading,
                  setSelections,
                  closeAcion: () => {
                    action();
                    onClose();
                  },
                }}
              />
            </Flex>
            <Flex align={"center"} mb="2" gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Tax (%):`}</Text>

              <Input
                w="3rem"
                fontWeight={600}
                type="number"
                value={fields.tax}
                onChange={(e) => {
                  let value = e.target.value;

                  if (!isNaN(value) && !value.match(/-/g)) {
                    if (value === "") value = 0;
                    setFields((prev) => {
                      return {
                        ...prev,
                        tax: value,
                      };
                    });
                  }
                }}
                textAlign={"right"}
                size={"xs"}
                p="2"
                rounded={"md"}
              />
            </Flex>
            <Flex align={"center"} gap={1} fontSize="1em">
              <Text fontWeight={600}>{`Total Amount:`}</Text>
              <Badge rounded={"md"} fontSize="1.2em">
                {`$${amount}`}
              </Badge>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            form="buyerInfo"
            w="40"
            colorScheme="blue"
            mr={3}
            cursor={!loading ? "pointer" : "not-allowed"}
          >
            Pay
          </Button>
          <ProductPaymentModal
            isOpen={paymentModalOpen}
            selectedProducts={selectedProducts}
            onClose={() => {
              setPaymentModalOpen(false);
              onClose();
            }}
            amount={amount}
            state={state}
            toast={toast}
            patient={patientsMap[patientId]}
          />

          <Button
            w="40"
            onClick={() => {
              setPaymentModalOpen(true);
              onClose();
              action();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
