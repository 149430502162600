import {useEffect, useState, useMemo} from "react";
import {
  Spinner,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tag,
  HStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {SortableColumnHeader} from "./SortableColumnHeader";
import {columns, handleSort} from "./Faxes/helpers";
import FilterByDate from "./Faxes/FilterByDate.js";
import {apiAction, crud, deleteFax, queryFaxFile, queryFaxes} from "../crudRequests";
import PreviewFile from "./PreviewFile";
import {crudStorageUpload} from "../storageRequests";
import {startOfDay, addDays, subWeeks} from "date-fns";
import {useToast} from "@chakra-ui/react";
import moment from "moment";
import FaxPatientsTable from "./FaxPatientsTable";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import DeleteButton from "./DeleteButton.js";
import {IoIosAttach} from "react-icons/io";

const SendToPatient = ({
  state,
  dispatch,
  open,
  setOpen,
  openPreview,
  dataUrl,
  location,
  faxId,
  requery,
}) => {
  const [selectedPid, setSelectedPid] = useState(null);
  const [attachLoading, setAttachLoading] = useState(false);
  const toast = useToast();
  if (open) {
    return (
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setSelectedPid(null);
        }}
        isCentered
        scrollBehavior={"inside"}
        size={"5xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="bg-white rounded-[25px] w-[1150px] p-8 px-10 grid ">
              <FaxPatientsTable
                dashState={state}
                dispatch={dispatch}
                selectedPid={selectedPid}
                setPatient={(patient) => setSelectedPid(patient?.pid)}
              />
              {/* </div> */}
              <div className="flex mt-4 gap-2">
                <button
                  onClick={() => openPreview(true)}
                  className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center p-x-10 text-md text-white bg-[#5754FF]"
                >
                  Preview File
                </button>
                <button
                  disabled={!selectedPid || attachLoading}
                  className="p-[10px_20px]  w-[150px] rounded-full flex justify-center items-center bg-[#272727] p-x-10 text-md text-white"
                  onClick={async () => {
                    const fileName = `${new Date()
                      .toLocaleString("en-CA", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hourCycle: "h23",
                      })
                      .split(/[.:,]+/)
                      .join(" ")}.pdf`;
                    const file = new File([dataUrl], fileName, {
                      type: "application/pdf",
                    });
                    setAttachLoading(true);
                    const res = await crudStorageUpload(
                      state,
                      file,
                      "brightlighthealth",
                      `${selectedPid}/Incoming Faxes/`
                    );
                    if (res.status === 200) {
                      // add the fax to assigned
                      if (location?.lid && location?.oid) {
                        await apiAction(state, {
                          action: "ATTACH-FAX",
                          payload: {
                            faxId,
                            patientId: selectedPid,
                          },
                          oid: location.oid,
                          lid: location.lid,
                        });
                      }
                      toast({
                        title: "File attached to patient!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      await requery();
                      setAttachLoading(false);
                      setOpen(false);
                    }
                  }}
                >
                  Attach
                </button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
};

const Fax = ({
  fax,
  state,
  setPreviewData,
  setOpen,
  setSendOpen,
  setFaxId,
  faxDocument,
  setFaxes
}) => {
  const toast = useToast();
  const attached =
    faxDocument?.status === "Attached"
      ? `${faxDocument?.patientData?.fName} ${faxDocument?.patientData?.lName}`
      : "No";
  useEffect(() => {
    queryFaxFile(state, fax.id, "s").then((res) => {
      const blob = new Blob([res?.data], {
        type: res.headers["content-type"],
      });
      const blobUrl = URL.createObjectURL(blob);
        setThumbnail(blobUrl);
      })
      .catch((err) => {
        setThumbnail(null)
        console.log("error", err);
      });
  }, [fax]);

  const handleClick = () => {
    const generatingToast = toast({
      title: "Generating Preview",
      description: "Please wait a few seconds for the preview to generate.",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });
    queryFaxFile(state, fax.id, "").then((res) => {
      setFaxId(fax.id);
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const blobUrl = URL.createObjectURL(blob);
      setPreviewData(blobUrl);
      setOpen(true);
      toast.close(generatingToast);
    });
  };

  const [thumbnail, setThumbnail] = useState(null);

  const handleDelete = async () => {
    try {
      const attached = faxDocument?.status === "Attached" ? true : false;
      const response = await deleteFax(state, fax.id, attached);
      if (response?.status === 200) {
        setFaxes(prevFaxes => prevFaxes.filter(f => f.id !== fax.id));
        toast({
          title: "Fax deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to delete fax",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error deleting fax",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Tr key={fax.id}>
      <Td>
        <Tag
          variant="solid"
          colorScheme={(() => {
            if (fax?.status === "success") {
              return "green";
            } else if (fax?.status === "failure") {
              return "red";
            } else {
              return "yellow";
            }
          })()}
        >
          {fax?.status}
        </Tag>
      </Td>

      <Td>
        <Tag
          variant="solid"
          colorScheme={attached === "No" ? "red" : "green"}
          className="whitespace-nowrap"
        >
          {attached}
        </Tag>
      </Td>

      <Td>{moment(fax?.created_at || "").format("MMMM Do YYYY, h:mm a")}</Td>
      <Td>{moment(fax?.completed_at || "").format("MMMM Do YYYY, h:mm a")}</Td>
      <Td>{fax?.caller_id || fax?.from_number}</Td>
      <Td>{fax?.to_number || fax?.recipients?.[0]?.phone_number}</Td>
      <Td cursor="pointer">
        {" "}
        {thumbnail && (
          <img
            onClick={handleClick}
            w="50px"
            h="15px"
            className="place-self-center"
            src={thumbnail}
            alt="file"
          />
        )}
      </Td>
      <Td>
        <HStack spacing={0}>
          <Tooltip hasArrow label="Attach to patient" rounded="sm">
            <IconButton
              colorScheme="purple"
              icon={<IoIosAttach/>}
              size='sm'
              m={1}
              onClick={() => {
                queryFaxFile(state, fax.id, "").then((res) => {
                  setFaxId(fax.id);
                  setPreviewData(res?.data);
                  setSendOpen(true);
                });
              }}
            />
          </Tooltip>
          <DeleteButton
            onDelete={handleDelete}
            label="Delete Fax"
          />
        </HStack>
      </Td>
    </Tr>
  );
};

export const AllFaxes = ({state, faxNumber, direction, dispatch}) => {
  const [faxes, setFaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [faxMap, setFaxMap] = useState();
  const [previewData, setPreviewData] = useState(null);
  const [open, setOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [sort, setSort] = useState({column: "created_at", direction: "desc"});
  const [filterRange, setFilterRange] = useState({
    created_before: startOfDay(addDays(new Date(), 1)).toISOString(), // Tomorrow
    created_after: startOfDay(subWeeks(new Date(), 1)).toISOString(), // A week back
  });

  const [deletedFaxes, setDeletedFaxes] = useState([]);
  const deletedFaxIds = useMemo(() => 
    new Set(deletedFaxes.map(fax => fax.faxId)),
    [deletedFaxes]
  );

  useEffect(() => {
    setLoading(true);
    queryFaxes(
      state,
      faxNumber,
      direction,
      filterRange.created_before,
      filterRange.created_after
    ).then(async (faxes) => {
      const filteredFaxes = (faxes?.data?.data || []).filter(fax => 
        !deletedFaxIds.has(fax.id)
      );
      setFaxes(filteredFaxes);
      setLoading(false);
    })
    .catch((err) => {
      console.log("error", err);
      setLoading(false);
    });
  }, [faxNumber, state, direction, deletedFaxIds]);

  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (state.selectedLocation && state.locations.length > 0) {
      const location = state.locations.filter(
        (loc) => loc?.lid === state?.selectedLocation
      )[0];
      setLocation(location);
    }
  }, [state]);

  const requery = () => {
    if (location?.lid && location?.oid) {
      crud(state, [
        {
          db: state.db || "blh",
          collection: "faxes",
          method: "aggregate",
          parameters: [
            {
              $match: {
                faxId: {$in: faxes.map((fD) => fD.id)},
              },
            },
            {
              $lookup: {
                from: "patients",
                localField: "patientId",
                foreignField: "pid",
                as: "patientData",
              },
            },
            {$unwind: {path: "$patientData"}},
          ],
        },
      ])
        .then((res) => {
          const map = new Map();
          res?.data?.[0]?.forEach((faxDoc) => {
            map.set(faxDoc.faxId, faxDoc);
          });
          setFaxMap(map);
        })
        .catch((err) => console.log("error getting deleted faxes list"));
    }
  };

  useEffect(() => {
    if (location?.lid && location?.oid && faxes) {
      crud(state, [
        {
          db: state.db || "blh",
          collection: "faxes",
          method: "aggregate",
          parameters: [
            {
              $match: {
                faxId: {$in: faxes.map((fD) => fD.id)},
              },
            },
            {
              $lookup: {
                from: "patients",
                localField: "patientId",
                foreignField: "pid",
                as: "patientData",
              },
            },
            {$unwind: {path: "$patientData"}},
          ],
        },
      ])
        .then((res) => {
          const map = new Map();
          res?.data?.[0]?.forEach((faxDoc) => {
            map.set(faxDoc.faxId, faxDoc);
          });
          setFaxMap(map);
        })
        .catch((err) => console.log("error getting deleted faxes list", err));
    }
  }, [location, faxes]);

  const getDeletedFaxes = async () => {
    if (location?.lid && location?.oid) {
      try {
        const response = await crud(state, [{
          db: state.db || "blh",
          collection: "faxes",
          method: "find",
          parameters: [{
            status: "deleted"
          }]
        }]);
        setDeletedFaxes(response?.data?.[0] || []);
      } catch (err) {
        console.log("error getting deleted faxes", err);
      }
    }
  };

  useEffect(() => {
    if (location?.lid && location?.oid) {
      getDeletedFaxes();
    }
  }, [location]);

  function reload({created_before, created_after}) {
    setLoading(true);
    queryFaxes(state, faxNumber, direction, created_before, created_after)
      .then((faxes) => {
        const filteredFaxes = (faxes?.data?.data || []).filter(fax => 
          !deletedFaxIds.has(fax.id)
        );
        setFaxes(filteredFaxes);
        requery();
      })
      .finally(() => setLoading(false));
  }

  const [faxId, setFaxId] = useState(null);

  return (
    <>
      <SendToPatient
        dataUrl={previewData}
        state={state}
        dispatch={dispatch}
        faxId={faxId}
        setOpen={setSendOpen}
        openPreview={setOpen}
        location={location}
        requery={requery}
        open={sendOpen}
      />
      <PreviewFile
        dataUrl={previewData}
        fileName="file.pdf"
        open={open}
        setOpen={setOpen}
        state={state}
        location={location}
      />
      <div>
        <div className="p-5 pl-0 ml-10">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <HStack w="full" justify={"space-between"}>
                <Button
                  onClick={() => {
                    reload({...filterRange});
                  }}
                >
                  Reload
                </Button>
                <FilterByDate {...{...filterRange, setFilterRange, reload}} />
              </HStack>
              <div className="flex gap-4 mt-4">
                {faxes?.length > 0 && (
                  <Table marginTop="4">
                    <Thead>
                      <Tr>
                        {columns.map((column, i) =>
                          column.sort ? (
                            <SortableColumnHeader
                              key={i}
                              label={column.label}
                              currentSort={sort}
                              column={column.column}
                              onSort={setSort}
                              onClick={(column, sort) => {
                              }}
                            />
                          ) : (
                            <Th key={i} textAlign={"center"}>
                              {column.label}
                            </Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {handleSort({
                        columnName: sort.column,
                        direction: sort.direction,
                        data: faxes,
                      }).map((fax, index) => (
                        <Fax
                          state={state}
                          faxDocument={faxMap?.get(fax.id)}
                          fax={fax}
                          key={index}
                          setOpen={setOpen}
                          setFaxId={setFaxId}
                          setPreviewData={setPreviewData}
                          setSendOpen={setSendOpen}
                          setFaxes={setFaxes}
                        />
                      ))}
                    </Tbody>
                  </Table>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
