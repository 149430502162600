import axios from "axios";

import {url} from "../../../../crudRequests";

export async function fileProxy(state, orgShareName, directory, fileName, email = false) {
  return axios({
    method: "GET",
    url: `${url}/fileProxy`,
    params: {
      s3BucketName: state.organization?.s3BucketName,
      fileName: fileName,
      directoryName: directory,
      orgShareName,
      email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    responseType: email ? "json" : fileName?.includes(".txt") ? "text" : "arraybuffer",
  });
}
