import {Box, Center, Spinner} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import {ClinicAidPDFButton} from './ClinicAidPDFButton'
import {viewFiles} from './helpers/viewFiles'
import {lightFormat} from 'date-fns'
import { ClinicAidPdf } from '../clinicAidClaimPdf/ClinicAidPDF'

export const ClinicAidSection = ({
  appointment,
  state,
  sendingAidClaim,
  setSendingAidClaim,
  setPreview,
  toast
}) => {
  const [loadingClinicAidPDF, setLoadingClinicAidPDF] = useState(false)
  const [clinicAidPDF, setClinicAidPDF] = useState([])

  const invoicesPath = appointment
  ? `${appointment?.pid || ""}/ClinicAidClaims/${lightFormat(new Date(appointment?.serviceDate ?? appointment?.ISOdate), "dd-MM-yy HH-mm aaa") +` (${appointment.aid})`}/`
  : ""

  useEffect(() => {
    setLoadingClinicAidPDF(true)
    setLoadingClinicAidPDF(true)
    viewFiles(state, setClinicAidPDF, setLoadingClinicAidPDF, invoicesPath, toast);
    viewFiles(state, setClinicAidPDF, setLoadingClinicAidPDF, invoicesPath, toast);
  }, [appointment])

  return (
    <>
      {appointment &&
        !appointment?.cancelled &&
        state.organization?.clinicAidUserId &&
        state.organization?.clinicAidUrl && (
        <button
          disabled={appointment?.invoiceAidClaimUuid}
          className="disabled:cursor-not-allowed disabled:bg-[#93e1a2] flex-1 focus:outline-none flex justify-center items-center bg-[#5754FF] rounded-md p-2 w-full text-white whitespace-nowrap mb-4"
          onClick={async () => {
            if (
              !sendingAidClaim &&
              appointment &&
              !appointment?.invoiceAidClaimUuid
            ) {
              setSendingAidClaim(true);
            }
          }}
        >
          <span className="font-medium text-sm">
            {appointment?.invoiceAidClaimUuid
              ? "ClinicAid Claim Submitted"
              : "File ClinicAid Claim"}
          </span>
        </button>
      )}
      <Box>
        {loadingClinicAidPDF ? (
          <Center h="3rem" w="3rem">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#827FFF"
              size="sm"
            />
          </Center>
        ) : clinicAidPDF.length > 0 ? (
          <ClinicAidPDFButton
            setPreview={setPreview}
            appointment={appointment}
            dashState={state}
            invoicesPath={invoicesPath}
            selectedFile={clinicAidPDF[0].name}
            toast={toast}
          />
        ) : (
          <span></span>
        )}
      </Box>
    </>
  )
}
