import {useNavigate} from "react-router-dom";
import {Box, Flex, Text, Avatar, Image, CloseButton} from "@chakra-ui/react";
import {NavItem} from "./NavItem";
import {FiUser} from "react-icons/fi";
import {FaRegCalendarCheck} from "react-icons/fa6";
import {LuUsers2} from "react-icons/lu";
import {MdAdminPanelSettings, MdFileCopy, MdTaskAlt} from "react-icons/md";
import {AiFillDollarCircle} from "react-icons/ai";
import {useMemo} from "react";
import verifyAccess from "../../utils/verifyAccess";

const LinkItems = [
  {name: "Schedule", route: "../", icon: FaRegCalendarCheck},
  {
    name: "Patients",
    route: "../patients",
    function: (setPatient) => {
      setPatient && setPatient(null);
    },
    icon: LuUsers2,
  },
  {
    name: "Admin",
    route: "../admin",
    routes: ["../admin"],
    icon: MdAdminPanelSettings,
  },
  {
    name: "Tasks",
    route: "../tasks",
    routes: ["../tasks"],
    icon: MdTaskAlt,
  },
  {
    name: "Billing",
    route: "../billing",
    routes: ["../billing"],
    icon: AiFillDollarCircle,
  },
  {
    name: "Reports",
    route: "../reports",
    routes: ["../reports"],
    icon: MdFileCopy,
  },
  {name: "User", route: "../user", icon: FiUser},
];

export default function SidebarContent({
  state,
  patient,
  setPatient,
  onClose,
  ...rest
}) {
  const navigate = useNavigate();
  const links = useMemo(() => {
    const accessSections = state.admin?.access
      ? state.admin.access.admAccessSections ??
        state.admin.access.docAccessSections ??
        null
      : null;
    if (accessSections) {
      return LinkItems.filter(
        (item) => accessSections[item.name.toLowerCase()] !== false
      );
    }
    return LinkItems;
  }, [state.admin?.access]);

  const isActive = (route, routes, name) => {
    const isInPath = routes
      ? routes.some((route) =>
          window.location.href.includes(route.split("/")[1])
        )
      : window.location.href.includes(route.split("/")[1]);
    return (
      (name !== "Schedule" && isInPath) ||
      (name === "Schedule" && window.location.href.split("/")[3]?.length < 2)
    );
  };

  return (
    <Box
      transition="3s ease"
      zIndex={9}
      bg={"blue.500"}
      borderRight="1px"
      borderRightColor={"gray.200"}
      w={{base: "full", md: 60}}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="24"
        alignItems="center"
        mx="8"
        mb="5"
        pb="4"
        justifyContent="space-between"
        borderBottomWidth="2px"
        borderBottomColor={"gray.200"}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mt="3">
          <Image h="41px" src={"../images/logo_white.png"} />
        </Box>
        <CloseButton
          display={{base: "flex", md: "none"}}
          color="white"
          onClick={onClose}
        />
      </Flex>
      {links.map((link, i) => (
        <Box key={i}>
          {(link.name !== "Reports" || verifyAccess(state, "reports")) && (
            <NavItem
              isActive={isActive}
              link={link}
              icon={link.icon}
              onClick={() => {
                if (
                  link.name == "Patients" &&
                  window.location.href.includes("patients") &&
                  !patient
                )
                  return;
                navigate(link.route, {
                  state: {
                    ...state,
                    firstLoading: true,
                    selectedAppointment: null,
                  },
                });
                link.function && link.function(setPatient);
              }}
            >
              {link.name}
            </NavItem>
          )}
          {link.name == "Patients" && patient && (
            <NavItem
              selectedPatient={true}
              setPatient={setPatient}
              isActive={isActive}
              link={link}
              onClick={() => {
                navigate(link.route, {
                  state: {
                    ...state,
                    firstLoading: true,
                    selectedAppointment: null,
                  },
                });
                link.function && link.function(setPatient);
              }}
            >
              <Avatar
                size={"xs"}
                name={patient.lName + ", " + patient.fName}
                color="white"
                bg="blue.700"
                m="1.5"
              />
              <Text>&nbsp; {patient.lName + ", " + patient.fName} </Text>
            </NavItem>
          )}
        </Box>
      ))}
    </Box>
  );
}
