import {fileProxy} from "./fileProxy";

export async function previewFile(
  dashState,
  path,
  selectedFile,
  setPreview,
  toast
) {
  const generatingToast = toast({
    title: "Generating Preview",
    description: "Please wait a few seconds for the preview to generate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });
  try {
    const file = await fileProxy(
      dashState,
      "brightlighthealth",
      path,
      selectedFile
    );

    if (file.headers["content-type"]?.includes("text/plain")) {
      setPreview({ data: file.data, fileName: selectedFile });
    } else {
      const blob = new Blob([file.data], { type: file.headers["content-type"] });
      const blobUrl = URL.createObjectURL(blob);
      setPreview({data: blobUrl, fileName: selectedFile});
    }
    toast.close(generatingToast);
  } catch {
    toast.close(generatingToast);
    toast({
      title: "This file cannot be previewed. ",
      description: "This file cannot be previewed. Please download it to view.",
      status: "warning",
      isClosable: true,
    });
  }
}
