import {
  Button,
  FormControl,
  FormLabel,
  VStack,
  useToast,
  Box,
  Textarea,
  Text,
} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {useContext, useEffect, useState} from "react";
import {updatePatient} from "./helpers/updatePatient";
import UnlimitedItemFields from "./Fields/UnlimitedItemField";
import {pharmAutocomplete} from "../Prescriptions/helpers/pharmAutocomplete";
import { SearchableSelect } from "../../SearchableSelect";

export default function MedicalHistory() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [pharmacies, setPharmacies] = useState([])
  const toast = useToast();

  useEffect(() => {
    const getPharmacies = async() => {
      try {
        const res = await pharmAutocomplete(dashState, "", [], 0, patient.preferredPharmacy || null)
        setPharmacies(res?.data ?? [])
      } catch (err) {
        setPharmacies([])
        console.log('error: ', err)
      }
    }
    getPharmacies()
  }, [])

  const loadOptions = async (inputValue) => {
    if (!inputValue) return []
    const results = await pharmAutocomplete(dashState, inputValue, [], 0)
    return results?.data?.map(pharmacy => ({
      ...pharmacy,
      value: pharmacy.phid,
      label: `${pharmacy.name} ${pharmacy.address} ${pharmacy.phone} ${pharmacy.fax}`
    })) || []
  }

  return (
    <form
      onSubmit={(e) =>
        updatePatient(e, patient, dashState, setIsLoading, toast, dispatch)
      }
    >
      <VStack spacing={4}>
        <Box
          gap={14}
          textAlign={"center"}
          display={{base: "block", lg: "flex"}}
          width={"700px"}
        >
          <UnlimitedItemFields
            label="Medications"
            items={patient.medications}
            setItems={(medications) => setPatient({...patient, medications})}
            fields={[
              {name: "name", placeholder: "Name", type: "medicationSelect"},
            ]}
            dashState={dashState}
          />

          <UnlimitedItemFields
            label="Allergies"
            items={patient.allergies}
            setItems={(allergies) => setPatient({...patient, allergies})}
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
            ]}
          />
        </Box>

        <Box
          gap={14}
          textAlign={"center"}
          display={{base: "block", lg: "flex"}}
          width={"700px"}
        >
          <UnlimitedItemFields
            label="Pre-diagnosed Medical Diseases"
            items={patient.preDiagnosedMedicalConditions}
            setItems={(preDiagnosedMedicalConditions) =>
              setPatient({...patient, preDiagnosedMedicalConditions})
            }
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
            ]}
          />

          <UnlimitedItemFields
            label="Pre-diagnosed Mental Health Disorders"
            items={patient.preDiagnosedMentalHealthDisorders}
            setItems={(preDiagnosedMentalHealthDisorders) =>
              setPatient({...patient, preDiagnosedMentalHealthDisorders})
            }
            fields={[
              {name: "name", placeholder: "Name", type: "text"},
            ]}
          />
        </Box>
        <Box
          gap={14}
          textAlign={"center"}
          display={{base: "block", lg: "flex"}}
          width={"700px"}
        >
          <FormControl my={2}>
            <FormLabel>Family Health/Mental Health Problems</FormLabel>
            <Textarea
              variant="flushed"
              placeholder="Father has had diabetes for 20 years. Aunt suffers from depression since childhood."
              value={patient.familyHealthProblems}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  familyHealthProblems: e.target.value,
                });
              }}
            ></Textarea>
          </FormControl>
          <SearchableSelect
            async={true}
            isRequired={false}
            label="Preferred Pharmacy"
            placeholder="Search for a pharmacy"
            value={patient.preferredPharmacy || null}
            onChange={(option) => {
              setPatient({
                ...patient,
                preferredPharmacy: option ? option.value : null,
              });
            }}
            loadOptions={loadOptions}
            defaultOptions={[
              {label: "None", value: null},
              ...pharmacies.map(option => ({
                ...option,
                label: `${option.name} ${option.address} ${option.phone} ${option.fax}`,
                value: option.phid,
              }))
            ]}
            color="black"
            fontWeight="medium"
            formatOptionLabel={(option) => option.value === null ? (
              <Box>
                <Text fontSize="sm">None</Text>
              </Box>
            ) : (
              <Box>
                <Text fontSize="sm">{option.name}</Text>
                <Text fontSize="xs">{option.address}</Text>
                <Text fontSize="xs">Phone: {option.phone}</Text>
                <Text fontSize="xs">Fax: {option.fax}</Text>
              </Box>
            )}
          />
        </Box>
        <Button
          isLoading={isLoading && true}
          colorScheme="blue"
          type="submit"
          my={4}
          px="10"
        >
          Save
        </Button>
      </VStack>
    </form>
  );
}
