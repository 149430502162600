import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {crud} from "../crudRequests";
import {BsPersonCircle} from "react-icons/bs";
import {IoMdCheckmarkCircle} from "react-icons/io";
let options = {
  productsTaxRate: "Products Tax Rate",
  instantMessaging: "Instant Messaging",
  transferPatient: "Transfer Patient",
  bookAppointment: "Book Appointment",
  documents: "Documents",
  notes: "Notes",
  patientName: "patient name",
  patientAge: "patient age",
  patientGender: "patient gender",
  patientEmail: "patient email",
  patientPhone: "patient phone",
  patientAddress: "patient address",
};
export default function PermissionSetter({
  userType,
  state,
  dispatch,
  userList,
  setNoSuperAdmins,
}) {
  const [selectedUsers, setSelectedUsers] = useState(() => {
    /*let id = userType === "noSuperAdmin" ? userList[0]?.id : userList[0]?.did;
    return userList[0] ? new Set(id) : new Set();*/
    return new Set();
  });
  const selectedUsersRef = useRef(selectedUsers);
  selectedUsersRef.current = selectedUsers;

  const [applyToAll, setApplyToAll] = useState(false);
  const [access, setAccess] = useState({});
  const [defining, setDefining] = useState(false);

  const ids = useMemo(() => {
    return userType === "noSuperAdmin"
      ? userList.map((u) => u.id)
      : Object.keys(state.doctorsPayload);
  }, [userList]);

  useEffect(() => {
    /* let id = userType === "noSuperAdmin" ? userList[0]?.id : userList[0]?.did;
    setSelectedUsers(() => {
      return userList[0] ? new Set([id]) : new Set();
    });
    setAccess(userList[0] ? userList[0].access : {});*/
    setSelectedUsers(new Set());
    setAccess({});
    setApplyToAll(false);
  }, [userList]);

  useEffect(() => {
    if (selectedUsersRef.current.size === 1) {
      let id = [...selectedUsersRef.current][0];
      setAccess(userList.find((u) => (u.id || u.did) === id)?.access || {});
    } else if (selectedUsersRef.current.size === 0) {
      setAccess({});
    }
  }, [selectedUsers]);

  async function definePermissions(params) {
    try {
      if (selectedUsers.size !== 0) {
        setDefining(true);
        let accessPayload = {};
        Object.keys(options).forEach((key) => {
          accessPayload[key] = false;
        });

        let collection = userType === "noSuperAdmin" ? "admins" : "doctors";
        let pArr = [];
        [...selectedUsers].forEach((id) => {
          let query = userType === "noSuperAdmin" ? {id} : {did: id};
          pArr.push(
            crud(state, [
              {
                db: state.db || "blh",
                collection,
                parameters: [
                  query,
                  {
                    $set: {
                      access: {
                        ...accessPayload,
                        ...access,
                        setBy: state.admin?.name || "",
                      },
                    },
                  },
                ],
                method: "updateOne",
              },
            ])
          );
        });

        await Promise.all(pArr);
        if (userType !== "noSuperAdmin") {
          const newDoctors = userList.map((dr) => {
            if (selectedUsers.has(dr.did)) {
              return {
                ...dr,
                access: {
                  ...accessPayload,
                  ...access,
                  setBy: state.admin?.name || "",
                },
              };
            }
            return dr;
          });
          dispatch({type: "UPDATE_MULTIPLE_DOCTORS", payload: newDoctors});
        } else {
          const newNsa = userList.map((ad) => {
            if (selectedUsers.has(ad.id)) {
              return {
                ...ad,
                access: {
                  ...accessPayload,
                  ...access,
                  setBy: state.admin?.name || "",
                },
              };
            }
            return ad;
          });
          setNoSuperAdmins(newNsa);
        }

        setDefining(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //console.log(access)

  return (
    <div className="shadow- flex flex-col  bg-gradient-to-b from-[#2d29ff44] to-[#7b79ff0f] rounded-3xl p-5 h-[320px] space-y-2">
      <h1 className="text-[#5754FF] font-semibold text-[1.7rem] text-center">
        {userType === "noSuperAdmin"
          ? "Non super-admin access"
          : "Clinician access"}
      </h1>
      <div className="w-full flex justify-evenly  flex-1 overflow-y-hidden p-2">
        <SelecctionList
          access={access}
          setAccess={setAccess}
          selectedUsers={selectedUsers}
          userList={userList}
          userType={userType}
        />
        <UserList
          ids={ids}
          setApplyToAll={setApplyToAll}
          applyToAll={applyToAll}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          userList={userList}
          definePermissions={definePermissions}
          defining={defining}
        />
      </div>
    </div>
  );
}

function UserList({
  selectedUsers,
  setSelectedUsers,
  userList,
  setApplyToAll,
  applyToAll,
  ids,
  defining,
  definePermissions,
}) {
  return (
    <div className="h-full w-[220px]  flex  flex-col text-left justify-between space-y-2 pb-2">
      <div className="h-full  w-[200px] flex flex-col space-y-2  overflow-hidden">
        <ul className="overflow-y-scroll max-h-[150px] w-full sbar2  p-1 pr-2 space-y-1">
          {(userList || []).map((e, i) => {
            const [first, ...last] = e.name.split(" ");
            return (
              <li
                onClick={() => {
                  let id = e.did || e.id;
                  !selectedUsers.has(id)
                    ? setSelectedUsers((prev) => {
                        prev.add(id);
                        let n = new Set(prev);
                        setApplyToAll(false);
                        return n;
                      })
                    : setSelectedUsers((prev) => {
                        prev.delete(id);
                        let n = new Set(prev);
                        setApplyToAll(false);
                        return n;
                      });
                }}
                key={i}
                style={{
                  backgroundColor: selectedUsers.has(e.did || e.id)
                    ? "#7371ff"
                    : null,
                }}
                className="[box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] hover:cursor-pointer bg-[#C1BFFF] w-full flex  items-center rounded-full p-1 py-1.5 space-x-1"
              >
                <span className="flex justify-center h-7 w-7 items-center shrink-0">
                  {e.photo ? (
                    <img
                      src={e.photo}
                      alt=""
                      className="hover:cursor-pointer hover:bg-[] h-full rounded-full drop-shadow-lg"
                    />
                  ) : (
                    <BsPersonCircle className="text-[1.5rem] text-white" />
                  )}
                </span>
                <div className=" flex  flex-col">
                  <p className="text-white text-[12px] leading-4  font-semibold">
                    {`${last}, ${first}`}
                  </p>
                  <span className="font-light text-[7px] text-white">
                    {e.accreditations || ""}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
        <p className="text-[#606FFF] text-xs font-medium flex items-center ml-1 space-x-2 ">
          <span
            className="h-4 w-4 rounded-[5px] border-2 inline-block border-[#7371ff] hover:cursor-pointer"
            style={{backgroundColor: applyToAll ? "#7371ff" : "#FFFFFF"}}
            onClick={() => {
              setApplyToAll((a) => {
                if (!a) {
                  let n = new Set(ids);
                  setSelectedUsers(n);
                  return true;
                } else {
                  let n = new Set();
                  setSelectedUsers(n);
                  return false;
                }
              });
            }}
          ></span>
          <span>Apply to all</span>
        </p>
      </div>
      <div className="rounded-2xl  text-background bg-[#C1BFFF] mx-2">
        <button
          type="button"
          className={
            defining
              ? "w-full py-1  flex justify-center items-center  rounded-2xl font-medium focus:outline-none border-none"
              : "w-full py-1 hover:bg-[#5754FF] flex justify-center items-center  hover:ring-0 rounded-2xl font-medium focus:outline-none border-none"
          }
          onClick={() => {
            definePermissions();
          }}
          disabled={defining}
        >
          <span>
            <IoMdCheckmarkCircle />
          </span>
          Define
          {defining && (
            <span className="">
              <svg
                className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25 stroke-[4px]"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}

function SelecctionList({access, setAccess, selectedUsers, userType}) {
  const [v, setV] = useState("fields");

  let opts =
    userType === "clinicians"
      ? {...options, allPatients: "All Patients"}
      : {...options, createDraftRx: "Create Draft Rx"};
  let views = {
    billing: "Billing",
    permissions: "Permissions",
    forms: "Forms",
    services: "Services",
    clinicians: "Clinicians",
    locations: "Locations",
    reports: "Reports",
  };

  let display = v === "fields" ? opts : views;

  return (
    <div className="h-full flex flex-col w-[220px] text-left space-y-2 overflow-y-hidden">
      {userType !== "clinicians" && (
        <div className="flex justify-evenly items-center w-[200px]">
          <span
            className={`cursor-pointer rounded-lg p-1 w-14 text-center font-semibold shadow-md border-2 text-[0.7rem] 
        ${
          v !== "fields"
            ? " border-white  text-white"
            : "border-[#5754FF]  text-[#5754FF]"
        }`}
            onClick={() => {
              setV("fields");
            }}
          >
            Fields
          </span>
          <span
            className={`cursor-pointer rounded-lg p-1 w-14 text-center font-semibold shadow-md border-2 text-[0.7rem] 
        ${
          v === "fields"
            ? " border-white  text-white"
            : "border-[#5754FF]  text-[#5754FF]"
        }`}
            onClick={() => {
              setV("views");
            }}
          >
            Views
          </span>
        </div>
      )}
      {
        <div className="flex-1 w-[200px]  overflow-y-hidden">
          <ul className="overflow-y-scroll h-full w-full sbar2  p-1 space-y-1">
            {Object.entries(display).map(([key, value], i) => (
              <li
                onClick={() => {
                  selectedUsers.size !== 0 &&
                    setAccess((prev) => {
                      if (v === "fields") return {...prev, [key]: !prev[key]};
                      else {
                        let a = prev.admAccessSections || {};
                        a[key] = !a[key];
                        return {...prev, admAccessSections: a};
                      }
                    });
                }}
                key={i}
                className="hover:cursor-pointer w-full flex items-center  p-1 space-x-1"
              >
                <span
                  className="flex justify-center h-5 w-5 rounded-full items-center"
                  style={{
                    backgroundColor:
                      access[key] || access.admAccessSections?.[key]
                        ? "#7371ff"
                        : "#FFFFFF",
                  }}
                ></span>

                <span className="font-semibold text-[1rem] text-[#5754FF]">
                  {value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
}
