import React, {useState, useEffect, useRef, useMemo, useContext} from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  VStack,
  useRadioGroup,
  useSteps,
  useToast,
} from "@chakra-ui/react";

import {verifyUser} from "../../../authRequests";
import SignaturePad from "./SignaturePad";
import PrescriptionModal from "./PrescriptionModal";
import PrescribeDrug from "./PrescribeDrug";
import PrescribeTherapy from "./PrescribeTherapy";
import updateOrgData from "./helpers/updateOrgData";

import {fetchPrescriptions} from "./helpers/fetchIntake";
import RadioCard from "./RadioCard";
import {crud} from "../../../crudRequests";
import {DispatchContext} from "../../../pages/Patients";
import {SearchableSelect} from "../../SearchableSelect";
import {alphabeticalSort} from "../../Tasks/helpers/alphabeticalSort";
import verifyAccess from "../../../utils/verifyAccess";

export const defaultMedicationObj = {
  drugName: "",
  qty: "1",
  refills: "0",
  form: "",
  route: "Oral Route",
  dosage: "",
  additionalNotes: "",
  signetur: "",
  frequency: "q.i.d",
  length_days: 0,
  autoexpire: false,
  start_date: null,
  // no_start_date: true,
  prn: false,
  end_date: "",
  drug_use: "Continuous",
  unit: "TAB",
};

export const defaultTherapyObj = {
  therapyName: "",
  length_days: 0,
  additionalNotes: "",
  files: [],
};
const initialValues = {
  drName: "",
  orgAddr: "",
  orgPhone: "",
  orgFax: "",
  ptName: "",
  ptAddr: "",
  ptDOB: "",
  ptHCN: "",
  signetur: "",
  signature: "",
  registrationId: "",
  license: "",
  drId: null,
  orgClinic: "",
  orgLocation: "",
};

export default function Prescription({state, patient}) {
  const loadedRef = useRef(null);
  const pid = patient.pid;
  const [inputValues, setInputValues] = useState(initialValues);
  const [pastPrescriptions, setPastPrescriptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [medications, setMedications] = useState([{...defaultMedicationObj}]);
  const [therapies, setTherapies] = useState([{...defaultTherapyObj}]);
  const [prescriptionType, setPrescriptionType] = useState("medication");
  const updateFormValue = (itemName, value) => {
    setInputValues((prev) => ({...prev, [itemName]: value}));
  };
  const dispatch = useContext(DispatchContext);
  const [oid, setOid] = useState(null);
  const toast = useToast();
  const [doctors, setDoctors] = useState([]);

  const doctorsMap = useMemo(() => {
    if (!state?.selectedDoctor) {
      const map = {};
      doctors.forEach(doctor => {
        map[doctor.did] = doctor;
      });
      return map;
    }
    return {};
  }, [doctors]);

  const populateDrData = () => {
    if (state?.selectedDoctor) {
      updateFormValue("drName", `${state?.selectedDoctor.lastName}, ${state?.selectedDoctor.firstName}`);
      updateFormValue("registrationId", state?.selectedDoctor?.registrationId);
      updateFormValue("signature", state?.selectedDoctor?.signature);
      updateFormValue("drId", state?.selectedDoctor?.did);
      setOid(state?.selectedDoctor?.oid);
    }
    if (state?.selectedLocation && state?.locations) {
      const location = state?.locations.find(
        (item) => item.lid === state?.selectedLocation
      );
      if (location) updateFormValue("orgLocation", location.name);
    }
  };
  const populatePatientData = () => {
    const data = patient;

    if (data?.pid) {
      updateFormValue("ptName", `${data.fName} ${data.lName}`);
      updateFormValue("ptGender", data.gender);
      updateFormValue("ptPhone", data.phone);
      updateFormValue(
        "ptAddr",
        [
          data.address1,
          data.address2,
          data.city,
          data.province,
          data.country,
          data.postalCode,
        ]
          .filter((item) => item && item !== "-")
          .join(", ")
      );
      updateFormValue("ptDOB", data.dob);
      updateFormValue("ptHCN", data?.healthCard?.healthCardNo);
    }
  };

  useEffect(() => {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    populateDrData();
  }, [state]);

  useEffect(() => {
    populatePatientData();
    const data = patient;
    if (data?.pid) {
      if (data?.prescriptions?.length > 0) {
        const pastDrugs = data?.prescriptions?.map((item) => item[0]);
        setPastPrescriptions((prev) => [
          ...new Set([...prev, ...pastDrugs].filter((e) => e)),
        ]);
      }
      if (data?.medications && data?.medications?.length > 0) {
        const medNames = data.medications.map((item) => item?.name);
        setPastPrescriptions((prev) => [
          ...new Set([...prev, ...medNames].filter((e) => e)),
        ]);
      }
    }
  }, [state, patient]);

  useEffect(() => {
    if (state?.selectedLocation) {
      const location = state?.locations.find(
        (location) => location.lid === state?.selectedLocation
      );
      updateFormValue("orgClinic", location?.name);
      updateFormValue("orgAddr", location?.address);
      updateFormValue("orgPhone", location?.phone);
      updateFormValue("orgFax", location?.phaxioNumber);
    }
  }, [state]);

  const getFormValue = (itemName) => {
    return inputValues[itemName];
  };

  // TODO: only add ones that are active
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchPromises = [
          fetchPrescriptions(
            (data) => {
              const prescriptions = data;
              if (prescriptions) {
                prescriptions.forEach((prescription) => {
                  const drugName = prescription.drugName;
                  if (
                    !pastPrescriptions.includes(drugName) &&
                    !prescription.expired
                  ) {
                    setPastPrescriptions((prev) => [...prev, drugName]);
                  }
                });
              }
            },
            state,
            {pid},
            toast
          )
        ];

        if (state?.userType === "admin" && verifyAccess(state, "createDraftRx") && !state?.selectedDoctor) {
          fetchPromises.push(
            crud(state, [{
              db: state.db || "blh", 
              collection: "doctors",
              method: "find",
              parameters: [{}]
            }])
          );
        }

        const responses = await Promise.all(fetchPromises);

        if (responses[1]?.data?.[0]) {
          setDoctors(responses[1].data[0]);
        } else if (state?.selectedDoctor) {
          setDoctors([state.selectedDoctor]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const options = ["medication", "therapy"];
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: "prescriptionType",
    value: prescriptionType,
    onChange: (e) => setPrescriptionType(e),
  });

  const group = getRootProps();

  const steps = useMemo(
    () => [
      {
        title: prescriptionType === "medication" ? "Medication" : "Therapy",
        description:
          prescriptionType === "medication"
            ? "Medication info"
            : "Therapy info",
      },
      {
        title: "Clinician",
        description: "Your info",
      },
      {
        title: "Patient",
        description: "Patient info",
      },
      {
        title: "Organization",
        description: "Organization info",
      },
    ],
    [prescriptionType]
  );

  const {activeStep, setActiveStep} = useSteps({
    index: 0,
    count: steps.length,
  });

  const sortedDoctorOptions = useMemo(() => {
    return alphabeticalSort(doctors, "lastName").map((doctor) => ({
      value: doctor.did,
      label: `${doctor.lastName}, ${doctor.firstName}`
    }));
  }, [doctors]);

  const validateStep = (step) => {
    switch (step) {
      case 0:
        if (prescriptionType === "medication") {
          let error = false;
          medications.forEach((med) => {
            if (
              !med.drugName ||
              !med.dosage ||
              !med.qty ||
              !med.refills ||
              !med.form ||
              !med.route ||
              !med.frequency ||
              !med.drug_use ||
              !med.unit
            ) {
              error = true;
            }
          });
          if (error) {
            toast({
              title: "Error",
              description: "Please fill out all medication fields",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return false;
          }
        } else {
          let error = false;
          therapies.forEach((therapy) => {
            if (!therapy.therapyName) {
              error = true;
            }
          });
          if (error) {
            toast({
              title: "Error",
              description: "Please fill out all therapy fields",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return false;
          }
        }
        return true;

      case 1:
        if (!getFormValue("drName") || !getFormValue("signature") || !getFormValue("registrationId")) {
          toast({
            title: "Error",
            description: "Please fill out all clinician information",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;

      case 2:
        if (
          !getFormValue("ptName") ||
          !getFormValue("ptDOB") ||
          !getFormValue("ptGender") ||
          !getFormValue("ptAddr") ||
          !getFormValue("ptPhone") ||
          !getFormValue("ptHCN")
        ) {
          toast({
            title: "Error",
            description: "Please fill out all patient information",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;

      case 3:
        if (
          !getFormValue("orgClinic") ||
          !getFormValue("orgLocation") ||
          !getFormValue("orgAddr") ||
          !getFormValue("orgPhone") ||
          !getFormValue("orgFax")
        ) {
          toast({
            title: "Error",
            description: "Please fill out all organization information",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  return (
    <Box w="full">
      <VStack className="ml-28 p-10  gap-10">
        {/* select the type of prescription */}
        <Box w="full" mt="2" p={4}>
          <Flex justify="space-between">
            <HStack {...group}>
              {options.map((value) => {
                const radio = getRadioProps({value});
                return (
                  <RadioCard key={value} {...radio}>
                    {value}
                  </RadioCard>
                );
              })}
            </HStack>
            <Button
              cursor="pointer"
              borderWidth="1px"
              borderRadius="md"
              boxShadow={"md"}
              px={5}
              py={6}
              backgroundColor={"white"}
              onClick={() => {
                setInputValues(initialValues);
                setMedications([{...defaultMedicationObj}]);
                setTherapies([{...defaultTherapyObj}]);
                populateDrData();
                populatePatientData();
              }}
              fontWeight="thin"
            >
              clear form
            </Button>
          </Flex>
        </Box>

        <Stepper w="full" size="lg" mt="4" index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription fontSize="sm" size="sm">
                  {step.description}
                </StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box w="full">
            {prescriptionType === "therapy" ? (
              <PrescribeTherapy
                therapies={therapies}
                setTherapies={setTherapies}
                state={state}
              />
            ) : (
              <PrescribeDrug
                medications={medications}
                setMedications={setMedications}
                pastPrescriptions={pastPrescriptions}
                state={state}
              />
            )}
            <Flex mt="6" justifyContent="space-between">
              <Button
                disabled={true}
                onClick={() => {}}
                textColor="grey"
                cursor="not-allowed"
              >
                Back
              </Button>
              <Button onClick={() => validateStep(activeStep) && setActiveStep(activeStep + 1)}>
                Next
              </Button>
            </Flex>
          </Box>
        )}
        {/* end of section */}
        {activeStep === 1 && (
          <Box w={"full"}>
            <Grid
              boxShadow="md"
              p={8}
              autoFlow="column"
              templateColumns="1fr auto auto"
              gap={4}
            >
              <FormControl my="2">
                <SearchableSelect
                  label="Clinician"
                  placeholder="-"
                  options={sortedDoctorOptions}
                  onChange={(e) => {
                    updateFormValue("drId", e.value);
                    updateFormValue("drName", `${doctorsMap[e.value]?.lastName}, ${doctorsMap[e.value]?.firstName}`);
                    updateFormValue("registrationId", doctorsMap[e.value]?.registrationId);
                    updateFormValue("signature", doctorsMap[e.value]?.signature);
                  }}
                  value={getFormValue("drId")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Signature</FormLabel>

                <SignaturePad
                  isDisabled={state?.userType === "admin"}
                  blobValue={getFormValue("signature")}
                  onCreate={(val) => {
                    updateFormValue("signature", val);
                    crud(state, [
                      {
                        db: state.db,
                        collection: "doctors",
                        parameters: [
                          {did: state?.selectedDoctor?.did},
                          {$set: {signature: val}},
                        ],
                        method: "updateOne",
                      },
                    ]);
                    crud(state, [
                      {
                        db: state.db,
                        collection: "doctors",
                        parameters: [
                          {did: state?.selectedDoctor?.did},
                          {$set: {signature: val}},
                        ],
                        method: "findOneAndUpdate",
                      },
                    ]).then((res) => {
                      dispatch({
                        type: "UPDATE_DOCTOR",
                        payload: {
                          did: state?.selectedDoctor?.did,
                          signature: val,
                        },
                      });
                    });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Registration Number (CPSO)</FormLabel>
                <Input
                  value={getFormValue("registrationId")}
                  onChange={(e) =>
                    updateFormValue("registrationId", e.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Flex justifyContent="space-between" mt="6">
              <Button onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Button>
              <Button onClick={() => validateStep(activeStep) && setActiveStep(activeStep + 1)}>
                Next
              </Button>
            </Flex>
          </Box>
        )}
        {/* end of section */}
        {activeStep === 2 && (
          <Box w={"full"}>
            <Grid
              boxShadow="md"
              p={8}
              // autoFlow="column"
              templateColumns="1fr 1fr 1fr "
              justifyContent="start"
              gap={4}
            >
              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>name</FormLabel>
                  <Input
                    value={getFormValue("ptName")}
                    onChange={(e) => updateFormValue("ptName", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>DOB</FormLabel>
                  <Input
                    value={getFormValue("ptDOB")}
                    onChange={(e) => updateFormValue("ptDOB", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>gender</FormLabel>
                  <Input
                    value={getFormValue("ptGender")}
                    onChange={(e) =>
                      updateFormValue("ptGender", e.target.value)
                    }
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>address</FormLabel>
                  <Input
                    value={getFormValue("ptAddr")}
                    onChange={(e) => updateFormValue("ptAddr", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>phone</FormLabel>
                  <Input
                    value={getFormValue("ptPhone")}
                    onChange={(e) => updateFormValue("ptPhone", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                {" "}
                <FormControl isRequired>
                  <FormLabel>HCN</FormLabel>
                  <Input
                    value={getFormValue("ptHCN")}
                    onChange={(e) => updateFormValue("ptHCN", e.target.value)}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Flex mt="6" justifyContent="space-between">
              <Button onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Button>
              <Button onClick={() => validateStep(activeStep) && setActiveStep(activeStep + 1)}>
                Next
              </Button>
            </Flex>
          </Box>
        )}
        {/* end of section */}
        {activeStep === 3 && (
          <Box w={"full"}>
            <Grid
              boxShadow="md"
              p={8}
              templateColumns="1fr auto auto auto"
              gap={4}
            >
              <GridItem colSpan={2}>
                <FormControl isRequired>
                  <FormLabel>clinic</FormLabel>
                  <Input
                    value={getFormValue("orgClinic")}
                    onChange={(e) =>
                      updateFormValue("orgClinic", e.target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isRequired>
                  <FormLabel>location</FormLabel>
                  <Input
                    value={getFormValue("orgLocation")}
                    onChange={(e) =>
                      updateFormValue("orgLocation", e.target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isRequired>
                  <FormLabel>address</FormLabel>
                  <Input
                    value={getFormValue("orgAddr")}
                    onChange={(e) => updateFormValue("orgAddr", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <FormControl isRequired>
                <FormLabel>phone</FormLabel>
                <Input
                  value={getFormValue("orgPhone")}
                  onChange={(e) => updateFormValue("orgPhone", e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>fax</FormLabel>
                <Input
                  value={getFormValue("orgFax")}
                  onChange={(e) => updateFormValue("orgFax", e.target.value)}
                />
              </FormControl>
            </Grid>

            <Flex mt="6" justifyContent="space-between">
              <Button onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Button>
              <Button
                onClick={() => {
                  for (let step = 0; step <= 3; step++) {
                    if (!validateStep(step)) {
                      return;
                    }
                  }
                  setModal(true);
                }}
                rounded="xl"
                px={10}
                w="150px"
                bg="black"
                textColor="white"
              >
                Preview
              </Button>
            </Flex>
          </Box>
        )}
      </VStack>

      <PrescriptionModal
        {...inputValues}
        state={state}
        pid={pid}
        medications={medications}
        therapies={therapies}
        prescriptionType={prescriptionType}
        onSave={() => {
          updateOrgData(state, oid, getFormValue);
        }}
        modal={modal}
        setModal={setModal}
      />
    </Box>
  );
}
