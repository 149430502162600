import {
  Box,
  Flex,
  Icon,
  useToast,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Text,
  Link,
} from "@chakra-ui/react";

import {useState, useEffect} from "react";
import {BiCopy} from "react-icons/bi";
import {MdEmail} from "react-icons/md";
import axios from "axios";
import {url, jwtDecode} from "../../../crudRequests";
import {TempLinkDialog} from "./TempLinkDialog";

export default function MeetingLinks({appointment, state}) {
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!appointment) {
      setOpen(false);
    }
  }, [appointment]);

  const resendAppointmentNotification = async () => {
    try {
      const location = state.locations.find(
        (l) => l.lid === state.selectedLocation
      );
      await axios.post(
        `${url}/patients/${appointment.pid}/resend-appointment-notifications`,
        {
          id: jwtDecode(state.jwt).id,
          db: state.db,
          org: state.organization,
          appointment,
          location,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.jwt || "",
          },
        }
      );

      toast({
        title: "Appointment notification resent",
        description:
          "The appointment notification has been resent to the patient.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error resending appointment notification:", error);
      toast({
        title: "Error",
        description:
          "Failed to resend appointment notification. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Accordion index={open ? [0] : []} my="4" allowToggle>
        <AccordionItem border="none">
          <Flex>
            <h2>
              <AccordionButton
                color={"blue.500"}
                borderBottom={"1px solid"}
                borderBottomColor={"blue.500"}
                fontWeight={500}
                fontSize={"13px"}
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
              >
                <Box as="span" flex="1" textAlign="left">
                  Meeting access
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
          </Flex>
          <AccordionPanel rounded={"sm"} p="2" bg="white">
            {appointment && (
              <VStack justify={"start"} flexWrap={"wrap"}>
                <Box
                  w="full"
                  fontSize={"13px"}
                  gap="2"
                  justify={"start"}
                  flexWrap={"wrap"}
                  whiteSpace={"pre-wrap"}
                >
                  <Text mr="1.5" as="span" fontWeight={600}>
                    Link:
                  </Text>
                  {appointment?.patientApptLink ? (
                    <>
                      <Link
                        isExternal
                        href={appointment?.patientApptLink}
                        color="blue.500"
                      >
                        {appointment?.patientApptLink}
                      </Link>

                      <Icon
                        onClick={() => {
                          navigator.clipboard.writeText(
                            appointment?.patientApptLink
                          );
                          toast({
                            title: "Link copied to clipboard",
                            status: "info",
                            duration: 2000,
                          });
                        }}
                        boxSize={4}
                        cursor={"pointer"}
                        as={BiCopy}
                        ml="2"
                      />
                    </>
                  ) : (
                    "N/A"
                  )}
                </Box>
                <Box
                  fontSize={"13px"}
                  gap="2"
                  justify={"start"}
                  flexWrap={"wrap"}
                  whiteSpace={"pre-wrap"}
                  w="full"
                >
                  <Text mr="1.5" as="span" fontWeight={600}>
                    Patient phone:
                  </Text>
                  <Text mr="" as="span">
                    {appointment?.pPhone || "N/A"}
                  </Text>

                  {appointment?.pPhone && (
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          appointment?.pPhone || ""
                        );
                        toast({
                          title: "Phone copied to clipboard",
                          status: "info",
                          duration: 2000,
                        });
                      }}
                      boxSize={4}
                      cursor={"pointer"}
                      as={BiCopy}
                      ml="2"
                    />
                  )}
                </Box>
                <Button
                  leftIcon={<MdEmail />}
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  onClick={resendAppointmentNotification}
                >
                  Resend Appointment Notification
                </Button>
                <TempLinkDialog
                  isOpen={isDialogOpen}
                  onClose={() => setDialogOpen(false)}
                  appointment={appointment}
                  state={state}
                />
              </VStack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
