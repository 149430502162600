import {CloseIcon} from "@chakra-ui/icons";
import {Box, Button} from "@chakra-ui/react";
import {useEffect} from "react";
import {apiAction} from "../crudRequests";

const PreviewFile = ({open, setOpen, dataUrl, fileName, state, location}) => {
  useEffect(() => {
    if (open && state && location) {
      apiAction(state, {
        action: "PREVIEW-FAX",
        payload: {
          fileName,
        },
        oid: location.oid,
        lid: location.lid,
      });
    }
  }, [open]);
  if (open)
    return (
      <Box className="fixed w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] left-0 top-0 z-50 cursor-pointer grid-rows-[auto_1fr] p-4">
        <Button onClick={() => setOpen(false)}>
          <CloseIcon />
        </Button>
        {(fileName?.endsWith(".jpg") || fileName?.endsWith(".png")) && (
          <Box className="place-self-center flex justify-center items-center h-[100%] w-[100%]">
            <img
              src={dataUrl}
              className="w-[250px] place-self-center"
            />
          </Box>
        )}
        {fileName?.endsWith(".pdf") && (
          <Box className="m-[50px]">
            <iframe
              src={dataUrl}
              className="w-[100%] h-[100%] min-h-[80vh]"
              title="file preview"
            />
          </Box>
        )}
        {fileName?.endsWith(".mp4") && (
          <Box className="m-[10px] place-self-center flex justify-center items-center">
            <video
              controls
              className="max-w-[1000px]"
              src={dataUrl}
            />
          </Box>
        )}
      </Box>
    );
};

export default PreviewFile;
