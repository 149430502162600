import {
  Box,
  Image,
  IconButton,
  useToast,
  Grid,
  GridItem,
  FormControl,
  Textarea,
  Text,
  Heading,
  Stack,
  StackDivider,
  Card,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Divider,
  CardHeader,
} from "@chakra-ui/react";
import {crud} from "../../../crudRequests";
import {useRef} from "react";
import {CloseIcon} from "@chakra-ui/icons";
import {apiAction} from "../../../crudRequests";
import {useReducer, useState, useEffect} from "react";
import {EditIcon} from "@chakra-ui/icons";
import {CardBody, CardFooter} from "react-bootstrap";

export default function PreviewFile({preview, setPreview, state, path}) {
  useEffect(() => {
    if (preview && path && preview.fileName) {
      apiAction(state, {
        action: "PREVIEW-REQUISITION",
        payload: {
          fileName: preview.fileName,
          path,
        },
      });
    }
  }, [preview, path]);
  const toast = useToast();
  const getFileType = (fileName) => {
    const ext = fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    const fileTypes = {
      image: ["jpg", "png", "jpeg"],
      pdf: ["pdf"],
      //video: ["mp4", "mov", "avi"],
      //audio: ["mp3", "wav", "ogg"],
      text: ["txt", "csv", "json", "js", "ts", "html", "css", "py"],
    };
    return (
      Object.keys(fileTypes).find((key) => fileTypes[key].includes(ext)) ||
      "unsupported"
    );
  };

  const fileType = preview && getFileType(preview?.fileName);

  if (fileType === "unsupported") {
    toast({
      title: "Unsupported file type",
      description:
        "This file is in an incompatible format, and therefore cannot be previewed. Please download it to view.",
      status: "warning",
      isClosable: true,
    });
    setPreview(false);
    return null;
  }

  const renderPreview = () => {
    switch (fileType) {
      case "image":
        return (
          <Image src={preview.data} m="auto" />
        );
      case "pdf":
        return (
          <Box
            as="iframe"
            src={preview.data}
            width="full"
            height="full"
          />
        );
      case "video":
        return (
          <Box
            as="video"
            controls
            src={preview.data}
            width="full"
            height="full"
            m="auto"
          />
        );
      case "audio":
        return (
          <Box
            as="audio"
            controls
            src={preview.data}
            width="full"
            height="full"
          />
        );
      case "text":
        return (
          <Box
            as="textarea"
            readOnly
            value={preview.data}
            width="full"
            height="full"
            p={4}
          />
        );
      default:
        return null;
    }
  };

  return (
    preview && (
      <Box
        position="fixed"
        w="full"
        h="full"
        bg="rgba(0,0,0,0.5)"
        left={0}
        top={0}
        zIndex={50}
        p={4}
      >
        <IconButton
          icon={<CloseIcon />}
          aria-label="Close preview"
          position="absolute"
          top={2}
          right={2}
          onClick={() => {
            setPreview(false);
          }}
        />
        <Grid
          templateColumns="repeat(12, 1fr)"
          templateRows="repeat(1, 1fr)"
          gap={6}
          minHeight="98vh"
          paddingBottom="12px"
        >
          <GridItem colSpan="11">
            <Box h="full" w="100%" overflow="auto">
              {renderPreview()}
            </Box>
          </GridItem>
          <GridItem colSpan="1">
            <FileNotesAside
              state={state}
              fileName={preview?.fileName}
              path={path}
            />
          </GridItem>
        </Grid>
      </Box>
    )
  );
}
function FileNotesAside({state, fileName, path}) {
  const [fileNotes, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "UPDATE_STATE":
        return action.data;
      case "ADD_NOTE":
        return [...state, action.data];
    }
    return state;
  }, []);
  const [noteText, setNoteText] = useState("");
  const {isOpen, onOpen, onClose} = useDisclosure();
  const btnRef = useRef();

  useEffect(() => {
    crud(state, [
      {
        db: state?.db || "blh",
        collection: "auditLogs",
        method: "aggregate",
        parameters: [
          {
            $match: {
              action: "ADD-FILE-NOTE",
              "payload.path": path,
              "payload.fileName": fileName,
            },
          },
          {
            $lookup: {
              from: "admins",
              localField: "triggeredBy",
              foreignField: "id",
              as: "triggeredByAdminData",
            },
          },
          {
            $unwind: {
              path: "$triggeredByAdminData",
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: "doctors",
              localField: "triggeredBy",
              foreignField: "did",
              as: "triggeredByDoctorData",
            },
          },
          {
            $unwind: {
              path: "$triggeredByDoctorData",
              preserveNullAndEmptyArrays: true,
            },
          },
        ],
      },
    ])
      .then((res) => {
        dispatch({type: "UPDATE_STATE", data: res?.data?.[0]});
      })
      .catch((err) => console.log("error getting deleted faxes list", err));
  }, []);
  return (
    <>
      <Button
        mt="3rem"
        ref={btnRef}
        colorScheme="blue"
        onClick={onOpen}
        leftIcon={<EditIcon />}
      >
        Notes
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            File notes
            <Text py="4" fontSize="sm">
              View a summary of all notes related to this file
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              align="stretch"
              h="100%"
            >
              <Box h="70%" overflow="auto">
                {fileNotes.map((note, i) => (
                  <FileNote
                    note={note?.payload?.note}
                    key={i}
                    author={
                      note.triggeredByDoctorData?.name ||
                      note.triggeredByAdminData?.name
                    }
                    timestamp={note.timestamp}
                  />
                ))}
              </Box>

              <Box h="20%">
                <Heading size="xs" textTransform="uppercase">
                  File notes
                </Heading>
                <Text pt="2" fontSize="sm">
                  <FormControl id="fileNotes">
                    <Textarea
                      variant="flushed"
                      placeholder="Extra notes relating to this file"
                      h="8rem"
                      w="full"
                      value={noteText}
                      onChange={(e) => {
                        setNoteText(e.target.value);
                      }}
                    ></Textarea>
                  </FormControl>
                </Text>
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={async () => {
                const username = state.admin
                  ? `${state.admin.firstName} ${state.admin.lastName}`
                  : `${state.doctor.firstName} ${state.doctor.lastName}`;
                dispatch({
                  type: "ADD_NOTE",
                  data: {
                    triggeredByData: {name: username},
                    payload: {note: noteText, fileName, path},
                    timestamp: new Date().toISOString(),
                  },
                });
                await apiAction(state, {
                  action: "ADD-FILE-NOTE",
                  payload: {
                    note: noteText,
                    fileName,
                    path,
                    triggeredByData: {},
                  },
                });
                setNoteText("");
              }}
            >
              Save note
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
function FileNote({note, author, timestamp}) {
  return (
    <>
      <Card bg="gray.100" boxShadow="md">
        <CardHeader>{author}</CardHeader>
        <CardBody>
          <Text px="1rem" fontSize="medium">
            {note}
          </Text>
        </CardBody>
        <CardFooter>
          <Text p="1rem" fontSize="xs">
            {new Date(timestamp).toLocaleDateString("en-CA", {
              minute: "2-digit",
              hour: "2-digit",
            })}
          </Text>
        </CardFooter>
      </Card>
      <Divider margin={"1rem"} />
    </>
  );
}
