import {crud} from "../../../crudRequests";
import {alphabeticalSort} from "../../Tasks/helpers/alphabeticalSort";

export const fetchGroups = async (dashState, setGroups) => {
  await crud(dashState, [
    {
      db: dashState.db,
      collection: "groups",
      parameters: [],
      method: "find",
    },
  ]).then((res) => {
    const data = res.data
    const groupsRes = data[0] ?? []
    const sortedGroups = alphabeticalSort(groupsRes, 'name')
    setGroups(sortedGroups);
  })
    .catch((err) => {
      setGroups([]);
      console.log(err);
    });
}
