import {fileProxy} from "../../../Patients/Files/helpers/fileProxy";
import {crud, supplementaryCrud} from "../../../../crudRequests";
export async function getFileData(
  appointment,
  dashState,
  path,
  selectedFile,
  setPreview,
  toast,
  setFetchingData,
  type,
  entity
) {
  async function getData() {
    let req = [
      {
        db: dashState.db,
        collection: "doctors",
        parameters: [{did: appointment?.did}],
        method: "findOne",
      },
      {
        db: dashState.db,
        collection: "patients",
        parameters: [{pid: appointment?.pid}],
        method: "findOne",
      },
      {
        db: dashState.db,
        collection: "insurers",
        parameters: [{iid: appointment?.tpp}],
        method: "findOne",
      },
    ];

    return crud(dashState, req);
  }

  async function getSuppData() {
    let req = [
      {
        db: "metadata",
        collection: "notifications",
        parameters: [
          {
            type: appointment?.nonSessionService
              ? "non_session_invioce_receipt"
              : "invioce_receipt",
          },
        ],
        method: "findOne",
      },
      {
        db: "metadata",
        collection: "notifications",
        parameters: [
          {
            type: appointment?.nonSessionService
              ? "non_session_invioce_receipt_insurers"
              : "invioce_receipt_insurers",
          },
        ],
        method: "findOne",
      },
    ];

    return supplementaryCrud(dashState, req);
  }

  const generatingToast = toast({
    title: "Generating Preview",
    description: "Please wait a few seconds for the preview to generate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });
  try {
    setFetchingData(true);

    let sup = await getSuppData();
    sup = sup.data;

    let res = await getData();
    res = res.data;
    let relatedData = {
      clinician: res[0],
      patient: res[1],
      insurer: res[2],
      template: sup,
      type,
      entity,
      appointment,
    };

    const file = await fileProxy(
      dashState,
      "brightlighthealth",
      path,
      selectedFile
    );

    setFetchingData(false);
    const blob = new Blob([file.data], {type: file.headers["content-type"]});
    const blobUrl = URL.createObjectURL(blob);
    setPreview({
      data: blobUrl,
      path,
      fileName: selectedFile,
      relatedData,
    });
    toast.close(generatingToast);
  } catch (e) {
    console.log(e.message);
    setFetchingData(false);
    toast.close(generatingToast);
    toast({
      title: "This file cannot be previewed. ",
      description: "This file cannot be previewed. Please download it to view.",
      status: "warning",
      isClosable: true,
    });
  }
}
