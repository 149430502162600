import React, {
  useState,
  useMemo,
} from "react";
import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Badge,
  Spinner,
  TableCaption,
  Tooltip,
  IconButton,
  Box,
} from "@chakra-ui/react";
import verifyAccess from "../../utils/verifyAccess";
import renderBlurredText from "../../utils/renderBlurredText";
import {getAge} from "../Patients/helpers/getAge";
import UpdateTask from "./UpdateTask";
import Pagination from "./PaginationTaskBar";
import {getTaskDate} from "./helpers/getTaskDate";
import {SortableColumnHeader} from "../SortableColumnHeader";
import {columns, status, statusColor} from "./helpers/const";
import {CheckIcon} from "@chakra-ui/icons";
import {updateTasks} from "./helpers/updateTasks";
import {useToast} from "@chakra-ui/react";
import DeleteButton from "../DeleteButton";
import {deleteTask} from "./helpers/deleteTask";

export default function GridTable({
  dashState,
  patient,
  fetchTasks,
  searchQuery,
  isLoading,
  tasks,
  setTasks,
  sort,
  setSort,
  doctors,
  groupedOptions,
  patients,
  patientsArr,
  showCompletedRef,
  admins
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [lastFetchedPage, setLastFetchedPage] = useState(0)
  const [loadingTasks, setLoadingTasks] = useState({})
  const toast = useToast()
  const itemsPerPage = patient ? 6 : 7

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (page % 5 === 0 && page !== lastFetchedPage && page > lastFetchedPage) {
      await fetchTasks(true, searchQuery, page, showCompletedRef.current, sort.column, sort.direction)
      setLastFetchedPage(page)
    }
  };

  const handleSort = (column, order) => {
    fetchTasks(false, searchQuery, 1, showCompletedRef.current, column, order)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const selectedData = useMemo(() => tasks?.slice(startIndex, startIndex + itemsPerPage), [tasks, startIndex, itemsPerPage])

  return (
    <>
      {isLoading ? (
            <Flex
              position="absolute"
              top="0"
              left="240px"
              right="0"
              bottom="0"
              justifyContent="center"
              alignItems="center"
              bg="rgba(255, 255, 255, 0.8)"
              zIndex="1"
            >
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Flex>
        ) : (
          <TableContainer
            maxW="10xl"
            flex="1"
            borderColor="gray.50"
            borderRadius="md"
            boxShadow="md"
            overflowX="auto"
            overflowY="scroll"
          >
            <Table size="md">
              <Thead bg="gray.100">
                <Tr>
                  {columns.map((column) => (
                    column.sort
                    ?
                    <SortableColumnHeader
                      label={column.label}
                      currentSort={sort}
                      column={column.column}
                      onSort={setSort}
                      onClick={(column, sort) => {
                        handleSort(column,  sort)
                      }}
                    />
                    :
                    <Th textAlign={'center'}>{column.label}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {selectedData?.map((task) => (
                  <Tr key={task.taskId}>
                    <Td fontSize={"15px"}>
                      {task.task}
                    </Td>
                    <Td>
                      <Box
                        fontSize={"15px"}
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                        maxHeight="90px"
                        overflowY="auto"
                        width={'200px'}
                      >
                        {task.description ?? ""}
                      </Box>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Badge colorScheme={statusColor[task.status]} rounded="md" px="2" py="0.5">
                        {status[task.status]}
                      </Badge>
                    </Td>
                    <Td maxW="250px" fontSize={"15px"}>
                      <Text fontWeight={"semibold"}>
                        {task.patient}
                      </Text>
                      <Text fontSize={"11px"}>
                        {verifyAccess(dashState, "dob")
                          ? patients[task.pid]?.dob
                            ? getAge(patients[task.pid].dob) + " y/o " 
                            : "unknown "
                          : renderBlurredText("00 y/o ", true)}{" "}
                        {patients[task.pid]?.gender}
                      </Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      {task.assignedToName}
                    </Td>
                    <Td fontSize={"15px"}>
                      {task.createdByName ? task.createdByName : task.createdById}
                    </Td>
                    <Td fontSize={"15px"}>
                      {getTaskDate(task.createdDate)}
                    </Td>
                    <Td fontSize={"15px"}>
                      {getTaskDate(task.due)}
                    </Td>
                    <Td fontSize={"15px"}>
                      {getTaskDate(task.completedDate)}
                    </Td>
                    <Td fontSize={"15px"} display={'flex'}>
                      <UpdateTask
                        dashState={dashState}
                        taskData={task}
                        patients={patients}
                        setTasks={setTasks}
                        patientId={patient?.pid}
                        doctors={doctors}
                        groupedOptions={groupedOptions}
                        patientsArr={patientsArr}
                        admins={admins}
                      />
                      <Tooltip hasArrow label="Complete" rounded={"sm"}>
                        <IconButton
                          colorScheme="green"
                          icon={<CheckIcon />}
                          isLoading={loadingTasks[task.taskId]}
                          size='sm'
                          m={1}
                          isDisabled={task.status === 2}
                          onClick={async() => {
                            setLoadingTasks({...loadingTasks, [task.taskId]: true})
                            const updatedTask = {...task, status: '2'}
                            let assignedToName = admins.find(admin => admin.id === task.assignedToId)
                            assignedToName = assignedToName
                            ? `${assignedToName.lastName}, ${assignedToName.firstName}`
                            : `${doctors[task.assignedToId]?.lastName}, ${doctors[task.assignedToId]?.firstName}`
                            await updateTasks(
                              updatedTask,
                              dashState,
                              setTasks,
                              assignedToName,
                              `${patients[task.pid]?.lName}, ${patients[task.pid]?.fName}`,
                              toast,
                            )
                            setLoadingTasks({...loadingTasks, [task.taskId]: false})
                          }}
                        />
                      </Tooltip>
                      <DeleteButton
                        onDelete={() => deleteTask(task.taskId, dashState, setTasks, toast)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              {tasks?.length === 0  && (<TableCaption>No tasks to display.</TableCaption>)}
            </Table>
            <Pagination totalItems={tasks?.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} currentPage={currentPage}/>
          </TableContainer>
        )
      }
    </>
  );
}
